<template>
  <div class="tryAssessmentHome">
    <div class="top">
      <p class="pp">品牌</p>
      <el-select class="sr" v-model="typeVal" placeholder="请选择" @change="ppbh">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" @click="add">增加试戴</el-button>
      <UPfile :type="'ContactLensesTry,RGP_D,RGP_J,VST_D,VST_J'" :isShowImgList="false" ></UPfile>
    </div>
    <div class="t-box">
      <ul class="title">
        <li v-for="(item, idx) in zjArr" :class="topIdx==idx?'xzs':''" @click="topIdx=idx">
          <i class="el-icon-error" @click="dele(idx)"></i>
          试戴{{idx+1}}
        </li>
      </ul>
    </div>

    <div class="content">
      <div v-for="(item, idx) in zjArr">
<!--        <CRT v-if="item.S_OP_Json.S_OP_CG_Type=='CRT' && idx==topIdx"></CRT>-->
        <MDW :postData="item" v-if="item.S_OP_Json.S_OP_CG_Type=='MDW' && idx==topIdx"></MDW>
      </div>
    </div>

    <div class="bottom">
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import pf from '@/publicFn/baseFn'
export default {
  name: "tryAssessmentHome",
  data() {
    return {
      typeVal: '',
      typeValzd: '',
      radio: 'CRT',
      input: '',
      options: [
        {
          value: 'CRT',
          label: 'CRT'
        },
        {
          value: 'MDW',
          label: '梦戴维'
        },
        {
          value: '',
          label: ''
        },
      ],
      zjArr: [],
      topIdx: 0,
    }
  },
  watch: {
    // radio: function (n, o) {
    //   this.typeVal = ''
    //   this.typeValzd = ''
    //   if (n == 'CRT') {
    //     this.options = this.OKarr
    //   }else {
    //     this.options = this.RGParr
    //   }
    // },
    zjArr: {
      handler(n, o) {

        this.$store.commit('corneaContact/upTryCorneaContact', n)
      },
      deep: true
    }
  },
  created() {
    if (!this.$store.state.users.CsUser) {
      this.$alert('请客户登陆,选择咨询或新建咨询', '提示')
          .then(res => {
            this.$router.push({path: '/home/acceptsHome'})
          })

      this.cshTime('contact_TE_optomtry')

      return
    }

    this._api.corneaContact.getTryAssessment('S_OP_ContactGlassMDW210624')
        .then(res => {
          // console.log(res)
          if (res.GetListResult && res.GetListResult.length > 0) {
            for (let i = 0; i < res.GetListResult.length; i++) {
              res.GetListResult[i].S_OP_Json = this._clJson(res.GetListResult[i].S_OP_Json)
            }
            this.$store.commit('corneaContact/upTryCorneaContact', res.GetListResult)
            this.zjArr = res.GetListResult
            // console.log(res.GetListResult)
          }
        })
  },
  methods: {
    add() {
      if (!this.typeVal) return this.$alert('请选择品牌', '提示')
      // console.log(this.typeValzd)
      this.zjArr.push(this.datas(this.typeValzd))
      // console.log(this.datas(this.typeValzd))
      this.topIdx = this.zjArr.length - 1
    },
    dele(idx) {
      this.zjArr.splice(idx, 1)
      this.topIdx = 0
    },

    ppbh(e) {
      this.typeValzd = e
      // if (this.radio == 'CRT') {
      //   if (e != 'CRT') {
      //     this.typeValzd = 'VST'
      //   }
      // }
    },

    datas(type) {
      let ExpertId = '00000000-0000-0000-0000-000000000000'
      if (this.$store.state.users.employees.ExpertId) ExpertId = this.$store.state.users.employees.ExpertId
      let obj = {}
      switch (type) {
        case 'CRT':
          obj = {
            S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
            S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
            S_OP_ExpertId: ExpertId,
            S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
            S_OP_ID: pf.generateGUID(),
            S_OP_Json: {
              isnew: true,
              S_OP_CG_A_ActivityOD: "",
              S_OP_CG_A_ActivityOS: "",
              S_OP_CG_A_CenterOD: "",
              S_OP_CG_A_CenterOS: "",
              S_OP_CG_A_EdgeOD: "",
              S_OP_CG_A_EdgeOS: "",
              S_OP_CG_A_FitOD: "",
              S_OP_CG_A_FitOS: "",
              S_OP_CG_A_ImproveOD: "",
              S_OP_CG_A_ImproveOS: "",
              S_OP_CG_A_LYJHOD: "",
              S_OP_CG_A_LYJHOS: "",
              S_OP_CG_A_LandOD: "",
              S_OP_CG_A_LandOS: "",
              S_OP_CG_A_LocationOD: "",
              S_OP_CG_A_LocationOS: "",
              S_OP_CG_A_ReversalOD: "",
              S_OP_CG_A_ReversalOS: "",
              S_OP_CG_A_SleepTestOD: "",
              S_OP_CG_A_SleepTestOS: "",
              S_OP_CG_A_TrySituationOD: "",
              S_OP_CG_A_TrySituationOS: "",
              S_OP_CG_ChooseId: this.$store.state.physicianVisits.xzConsulting,
              S_OP_CG_IsSubmit: "",
              S_OP_CG_Order: "",
              S_OP_CG_R_DCOD: "",
              S_OP_CG_R_DCOS: "",
              S_OP_CG_R_DSOD: "",
              S_OP_CG_R_DSOS: "",
              S_OP_CG_R_FKOD: "",
              S_OP_CG_R_FKOS: "",
              S_OP_CG_R_HVIDOD: "",
              S_OP_CG_R_HVIDOS: "",
              S_OP_CG_R_SKOD: "",
              S_OP_CG_R_SKOS: "",
              S_OP_CG_R_VAOD: "",
              S_OP_CG_R_VAOS: "",
              S_OP_CG_R_XOD: "",
              S_OP_CG_R_XOS: "",
              S_OP_CG_Remark: "",
              S_OP_CG_Try_BCOD: "",
              S_OP_CG_Try_BCOS: "",
              S_OP_CG_Try_Brand: '',
              S_OP_CG_brand:'CRT',
              S_OP_CG_Try_DIAOD: "",
              S_OP_CG_Try_DIAOS: "",
              S_OP_CG_Try_LZA2OD: "",
              S_OP_CG_Try_LZA2OS: "",
              S_OP_CG_Try_LZAOD: "",
              S_OP_CG_Try_LZAOS: "",
              S_OP_CG_Try_NoOD: "",
              S_OP_CG_Try_NoOS: "",
              S_OP_CG_Try_OnDiopter_DCOD: "",
              S_OP_CG_Try_OnDiopter_DCOS: "",
              S_OP_CG_Try_OnDiopter_DSOD: "",
              S_OP_CG_Try_OnDiopter_DSOS: "",
              S_OP_CG_Try_OnDiopter_VAOD: "",
              S_OP_CG_Try_OnDiopter_VAOS: "",
              S_OP_CG_Try_OnDiopter_XOD: "",
              S_OP_CG_Try_OnDiopter_XOS: "",
              S_OP_CG_Try_PowerOD: "",
              S_OP_CG_Try_PowerOS: "",
              S_OP_CG_Try_RZD2OD: "",
              S_OP_CG_Try_RZD2OS: "",
              S_OP_CG_Try_RZDOD: "",
              S_OP_CG_Try_RZDOS: "",
              S_OP_CG_Type: "MDW",
              S_OP_DTPics: "",
              S_OP_JTPics: ""
            },
            S_OP_Model: "S_OP_ContactGlassMDW210624",
            S_OP_Time: "",
            S_OP_Type: "ContactGlass",
            S_OP_XML: "",
          }
          break
        case 'MDW':
          obj = {
            S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
            S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
            S_OP_ExpertId: ExpertId,
            S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
            S_OP_ID: pf.generateGUID(),
            S_OP_Json: {
              isnew: true,
              S_OP_CG_Try_adjustFactorOD: '',
              S_OP_CG_Try_adjustFactorOS: '',
              S_OP_CG_Try_THINOD: '',
              S_OP_CG_Try_THINOS: '',
              S_OP_CG_Try_SOZOD: '',
              S_OP_CG_Try_SOZOS: '',
              S_OP_CG_Try_JiangFuOD: '',
              S_OP_CG_Try_JiangFuOS: '',
              S_OP_CG_Try_JiHuOD: '',
              S_OP_CG_Try_JiHuOS: '',
              S_OP_CG_Try_HuanQuMianOD: '',
              S_OP_CG_Try_HuanQuMianOS: '',
              S_OP_CG_Try_EValueOD: '',
              S_OP_CG_Try_EValueOS: '',
              S_OP_CG_Try_DingWeiHuOD: '',
              S_OP_CG_Try_DingWeiHuOS: '',
              S_OP_CG_Try_ColorOD: '',
              S_OP_CG_Try_ColorOS: '',
              S_OP_CG_Submit: "",
              S_OP_CG_R_EOD: '',
              S_OP_CG_R_EOS: '',
              S_OP_CG_A_ActivityOD: "",
              S_OP_CG_A_ActivityOS: "",
              S_OP_CG_A_BesideCenterOD:'',
              S_OP_CG_A_BesideCenterOS:'',
              S_OP_CG_A_CenterOD: "",
              S_OP_CG_A_CenterOS: "",
              S_OP_CG_A_EdgeOD: "",
              S_OP_CG_A_EdgeOS: "",
              S_OP_CG_A_FitOD: "",
              S_OP_CG_A_FitOS: "",
              S_OP_CG_A_ImproveOD: "",
              S_OP_CG_A_ImproveOS: "",
              S_OP_CG_A_LYJHOD: "",
              S_OP_CG_A_LYJHOS: "",
              S_OP_CG_A_LandOD: "",
              S_OP_CG_A_LandOS: "",
              S_OP_CG_A_LocationOD: "",
              S_OP_CG_A_LocationOS: "",
              S_OP_CG_A_ReversalOD: "",
              S_OP_CG_A_ReversalOS: "",
              S_OP_CG_A_SleepTestOD: "",
              S_OP_CG_A_SleepTestOS: "",
              S_OP_CG_A_TrySituationOD: "",
              S_OP_CG_A_TrySituationOS: "",
              S_OP_CG_ChooseId: this.$store.state.physicianVisits.xzConsulting,
              S_OP_CG_Order: "",
              S_OP_CG_R_DCOD: "",
              S_OP_CG_R_DCOS: "",
              S_OP_CG_R_DSOD: "",
              S_OP_CG_R_DSOS: "",
              S_OP_CG_R_FKOD: "",
              S_OP_CG_R_FKOS: "",
              S_OP_CG_R_HVIDOD: "",
              S_OP_CG_R_HVIDOS: "",
              S_OP_CG_R_SKOD: "",
              S_OP_CG_R_SKOS: "",
              S_OP_CG_R_VAOD: "",
              S_OP_CG_R_VAOS: "",
              S_OP_CG_R_XOD: "",
              S_OP_CG_R_XOS: "",
              S_OP_CG_Remark: "",
              S_OP_CG_Try_ACOD: "",
              S_OP_CG_Try_ACOS: "",
              S_OP_CG_Try_Brand: '',
              S_OP_CG_brand:'梦戴维',
              S_OP_CG_Try_CPOD: "",
              S_OP_CG_Try_CPOS: "",
              S_OP_CG_Try_DIAOD: "",
              S_OP_CG_Try_DIAOS: '',
              S_OP_CG_Try_OnDiopter_DCOD: "",
              S_OP_CG_Try_OnDiopter_DCOS: "",
              S_OP_CG_Try_OnDiopter_DSOD: "",
              S_OP_CG_Try_OnDiopter_DSOS: "",
              S_OP_CG_Try_OnDiopter_VAOD: "",
              S_OP_CG_Try_OnDiopter_VAOS: "",
              S_OP_CG_Try_OnDiopter_XOD: "",
              S_OP_CG_Try_OnDiopter_XOS: "",
              S_OP_CG_Try_RPOD: "",
              S_OP_CG_Try_RPOS: "",
              S_OP_CG_Try_TypeOD: "",
              S_OP_CG_Try_TypeOS: "",
              S_OP_CG_Type: "MDW",
              S_OP_DTPics: "",
              S_OP_JTPics: "",
            },
            S_OP_Model: "S_OP_ContactGlassMDW210624",
            S_OP_Time: "",
            S_OP_Type: "ContactGlass",
            S_OP_XML: "",
          }
          break
      }
      return obj
    },

    save() {
      if ( this.$store.state.Time.timeObj.contact_TE_optomtry.ENTER != 0) {
        let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.contact_TE_optomtry.ENTER)/1000 + Number(this.$store.state.Time.timeObj.contact_TE_optomtry.EXIT)
        this._api.publicApi.addTime('contact_TE_optomtry,contact_TE_trailSlice,contact_TE_sliceOptometry,contact_TE_dynamic,contact_TE_static', times)
        this.jsTime('contact_TE_optomtry', 1)
      }
      this._api.corneaContact.saveTryAssessment('S_OP_ContactGlassMDW210624')
    },


    cshTime(keys) {
      // console.log(this.$store.state.Time.timeObj[keys].EXIT)
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    }
  },
  components: {
    MDW: () => import('@/components/jqDIY/trysd/MDW'),
    CRT: () => import('@/components/jqDIY/trysd/MDW'),
    UPfile: () => import('@/components/CommonComponents/upFile')
  },
  beforeDestroy() {
    if (this.$store.state.Time.timeObj.contact_TE_optomtry.ENTER != 0) {
      this.jsTime('contact_TE_optomtry')
    }
  }
}
</script>

<style scoped lang="scss">
.tryAssessmentHome {
  width: 100vw;
  height: 87vh;
  overflow: hidden;
}
.top {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: .3rem;
  .pp {
    padding-left: 1.5rem;
    padding-right: .1rem;
  }
  .sr {
    width: 2.2rem;
    margin-right: .7rem;
  }
}
.t-box {
  width: 90%;
  margin: 0 auto;
  padding-top: .2rem;
  border-bottom: 1px solid #40a7f6;
  overflow: auto;
  .title {
    width: 100%;
    display: flex;
    justify-content: start;
    padding-bottom: .1rem;
    li {
      padding: .1rem .2rem;
      border-radius: 5px;
      background: #efefef;
      position: relative;
      cursor: pointer;
      margin-right: .15rem;
      i {
        position: absolute;
        right: -7px;
        top: -7px;
        color: #fa0202;
        font-size: 15px;
      }
      &:hover {background: #40a7f6;color: #ffffff;}
    }
    .xzs {background: #40a7f6;color: #ffffff;}
  }
}

.content {
  width: 90%;
  height: 55vh;
  margin:  0 auto;
  overflow-y: auto;
  padding-bottom: 30px;
}

.bottom {
  width: 100%;
  position: absolute;
  bottom: 2vh;
}
</style>
